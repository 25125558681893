$(function() {
    // 10Mb
    const MAXFILESIZE = 1e+7;
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            const block = entry.target;
            if (entry.isIntersecting) {
                block.classList.add('animated');
            }else {
                block.classList.remove('animated');
            }
        })
    })
    const arr = document.querySelectorAll('.content')
    arr.forEach(i => {
        observer.observe(i)
    })

    const openMenu = ()=> {
       $('.menu-list_wrapper').toggleClass('show');
       $('.menu-list_btn').toggleClass('rotate');
       $('body').toggleClass('menu-opened');
    }

    const closeMenu = () => {
        $('.menu-list_wrapper').removeClass('show');
        $('.menu-list_btn').removeClass('rotate');
        $('body').removeClass('menu-opened');
    }

    const outsideClick = (e) => {
        const menu = $('.menu-list_wrapper');
        const btn = $('.menu-list_btn');
        if(btn.is(e.target)) {
            return false;
        }
        if (!menu.is(e.target) && menu.has(e.target).length === 0) {
           closeMenu();
        }
    }

    const canvasPainting = () => {
        let points = [];
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        const canvasBlock = $('.paint-canvas');
        canvas.width = canvasBlock.innerWidth();
        canvas.height = canvasBlock.innerWidth() / 1.5;

        // Загрузка изображения
        const img = new Image();
        img.src = '/dist/img/paint-bg.jpg';
        img.addEventListener('load', function(){
            ctx.drawImage(img, 0, 0);

            let mouseX = 0;
            let mouseY = 0;

            let offsetX;
            let offsetY;

            // Стиль линии
            ctx.strokeStyle = 'rgb(0, 0, 0)';
            ctx.lineWidth = 2;
            let isDrawing = false;


            function start(event) {
                setMouseCoordinates(event);
                isDrawing = true;
                ctx.beginPath();
                ctx.moveTo(mouseX, mouseY);

                points.push({
                    x: mouseX,
                    y: mouseY,
                    mode: "begin"
                });
            }

            function draw(event) {
                setMouseCoordinates(event);
                if(isDrawing){
                    ctx.lineTo(mouseX, mouseY);
                    ctx.stroke();
                    points.push({
                        x: mouseX,
                        y: mouseY,
                        mode: "draw"
                    });
                }
            }

            function stop (event) {
                setMouseCoordinates(event);
                isDrawing = false;
                points.push({
                    x: mouseX,
                    y: mouseY,
                    mode: "end"
                });
            }

            let ongoingTouches = [];

            function handleStart(event) {
                event.preventDefault();
                const touches = event.changedTouches;
                offsetX = canvas.getBoundingClientRect().left;
                offsetY = canvas.getBoundingClientRect().top;
                for (let i = 0; i < touches.length; i++) {
                    ongoingTouches.push(copyTouch(touches[i]));
                }
            }

            function handleMove(event) {
                event.preventDefault();
                const touches = event.changedTouches;
                for (let i = 0; i < touches.length; i++) {
                    const idx = ongoingTouchIndexById(touches[i].identifier);
                    if (idx >= 0) {
                        ctx.beginPath();
                        ctx.moveTo(ongoingTouches[idx].clientX - offsetX, ongoingTouches[idx].clientY - offsetY);
                        ctx.lineTo(touches[i].clientX - offsetX, touches[i].clientY - offsetY);
                        ctx.closePath();
                        ctx.stroke();
                        ongoingTouches.splice(idx, 1, copyTouch(touches[i]));  // swap in the new touch record
                    }
                }
            }

            function handleEnd(event) {
                event.preventDefault();
                const touches = event.changedTouches;
                for (let i = 0; i < touches.length; i++) {
                    let idx = ongoingTouchIndexById(touches[i].identifier);
                    if (idx >= 0) {
                        ongoingTouches.splice(idx, 1);  // remove it; we're done
                    }
                }
            }

            function ongoingTouchIndexById(idToFind) {
                for (let i = 0; i < ongoingTouches.length; i++) {
                    const id = ongoingTouches[i].identifier;
                    if (id === idToFind) {
                        return i;
                    }
                }
                return -1;
            }

            function copyTouch({ identifier, clientX, clientY }) {
                return { identifier, clientX, clientY };
            }

            // Обработчики рисования мышкой
            canvas.addEventListener('mousedown', start, false);
            canvas.addEventListener('mousemove', draw, false);
            canvas.addEventListener('mouseup', stop, false);

            // Touch Events
            canvas.addEventListener('touchstart', handleStart, false);
            canvas.addEventListener('touchend', handleEnd, false);
            canvas.addEventListener('touchmove', handleMove, false);

            function setMouseCoordinates(event) {
                mouseX = event.offsetX;
                mouseY = event.offsetY;
            }

            // Кнопка Очистить
            $('#clear').click(function(){
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);
                return false;
            });

            // Kнопка Отменить
            function redrawAll() {
                if (points.length == 0) {
                    return;
                }
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);
                for (let i = 0; i < points.length; i++) {
                    let pt = points[i];
                    if (pt.mode == "begin") {
                        ctx.beginPath();
                        ctx.moveTo(pt.x, pt.y);
                    }
                    ctx.lineTo(pt.x, pt.y);
                    if (pt.mode == "end" || (i == points.length - 1)) {
                        ctx.stroke();
                    }
                }
                ctx.stroke();
            }

            function undoLast() {
                points.pop();
                redrawAll();
            }

            // Кнопка Отменить
            let interval;
            $("#undo").mousedown(function(){
                interval = setInterval(undoLast, 50);
                $(this).removeClass('hovered');
            }).mouseup(function(){
                clearInterval(interval);
                $(this).addClass('hovered');
            }).click(function(){
                return false;
            });
        });
    }

    // Отправка формы
    $('#form').submit(function(e){
        e.preventDefault();
        $('#canvas-img').val(canvas.toDataURL("image/jpg"));
        const $form = $('#form');
        const url = $form.attr('action');
        let data = new FormData($form[0]);


        console.log([...data]);

        // TODO check sending, response processing
        $.ajax({
            url: url,
            data: data,
            processData: false,
            contentType: 'application/json',
            type: 'POST',
            success: (res) => {
                console.log(res);
            },
            error: ($xhr) => {
                console.log($xhr.responseJSON.message);
            }
        });

        return true;
    });

    const changeAttach = () => {
        const input =  $('#img-attach');
        const fileName =  input[0].files?.[0]?.name || '';
        const fileSize =  input[0]?.files[0]?.size || '';
        const textField = $('.img-attach-label');
        const close = $('.cross')
        if(fileSize > MAXFILESIZE) {
            alert(`Слишком большой файл, надо меньше ${MAXFILESIZE/1e6}Мб (если ты понимаешь о чём я)`);
            return false;
        }

        if( input.val().length){
            textField.text(fileName);
            close.removeClass('--hide');
        } else {
            textField.text(textField.data('text'));
            close.addClass('--hide');
        }
    }

    const clearAttach = () => {
        $('#img-attach').val('');
        changeAttach();
    }

    $('.js-open-menu').on('click', openMenu);
    $('.menu-list_link').on('click', closeMenu);
    $(document).on('mouseup', outsideClick);
    $(window).on('resize', canvasPainting);
    $('#img-attach').on('change', changeAttach);
    $('.cross').on('click', clearAttach);

    canvasPainting();
});